<template>
  <div class="bg">
    <div class="banner">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <div class="title">
        <div>媒体报道</div>
        <div class="bottom-line"></div>
      </div>
      <div v-loading="loading">
        <el-empty description="暂无数据" v-if="dataList.length === 0 && !loading"></el-empty>
        <div v-else>
          <div class="content" v-for="(item, index) in dataList" :key="index" @click="toDetail(item)">
            <div class="left-img">
              <img :src="item.coverPictureUrl" alt="" width="100%" height="100%">
            </div>
            <div class="right-text">
              <div class="left">
                <div class="text-title single-line-ellipsis">{{ item.title }}</div>
                <div class="text-content multi-line-ellipsis" v-html="item.content"></div>
              </div>
              <div class="right">
                <div class="day">{{ item.date.slice(8) }}</div>
                <div class="date">{{ item.date.slice(0, 7) }}</div>
              </div>
            </div>
          </div>
          <div class="pagination" v-if="pagination.totalSize > pagination.pageSize">
            <p-pagination-base
              :currentPage.sync="pagination.currentPage"
              :pageSize.sync="pagination.pageSize"
              :page-sizes="[20, 30, 40]"
              :total="pagination.totalSize"
              v-show="!this.isEmpty"
              @size-change="handleSizeChange"
              @currentPageChange="changeCurrent"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb.vue'
  import PPaginationBase from '@comp/project/pagination/PPaginationBase'
  import { newsList } from '@/api/mock/homeIndex'
  import { getAction } from '@api/manage'
  import { message } from 'ant-design-vue'

  export default {
    name: 'AuditList',
    components: { Breadcurmb, PPaginationBase },
    data() {
      return {
        loading: false,
        menuList: [
          {
            name: '媒体报道',
            path: ''
          }
        ],
        dataList: [],
        pagination: {
          currentPage: 1,
          pageSize: 5,
          totalSize: 0
        }
      }
    },
    computed: {
      isEmpty() {
        return this.dataList.length === 0
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.loading = true
        let promiseList = [this.loadData(1, false)]
        Promise.all(promiseList).finally(res => {
          this.loading = false
        })
      },
      changeCurrent(page) {
        this.pagination.currentPage = page
        this.loadData(page)
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.loadData(1)
      },
      loadData(arg, isLoading = true) {
        return new Promise((resolve, reject) => {
          if (isLoading) {
            this.loading = true
          }
          if (arg === 1) {
            this.pagination.currentPage = 1
          }
          let params = {
            dynamicType: '1',
            queryPage: this.pagination.currentPage,
            querySize: this.pagination.pageSize
          }
          getAction('/api/v1/dms/centralDynamics/detail', params).then((res) => {
            if (res.success) {
              if (res && res.list && res.list.length > 0) {
                this.dataList = res.list.map(item => {
                  return {
                    id: item.id,
                    coverPictureUrl: item.coverImage,
                    title: item.title,
                    date: item.releaseTime.slice(0, 10),
                    content: item.content
                  }
                })
                this.pagination.currentPage = res.page
                this.pagination.pageSize = res.size
                this.pagination.totalSize = res.totalSize
              } else {
                this.dataList = []
              }
              resolve(res)
            } else {
              this.dataList = []
              message.error(res.message)
              reject(res)
            }
          }).catch((err) => {
            this.dataList = []
            message.error(err.message)
            reject(err)
          }).finally(() => {
            this.loading = false
          })
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
        })
      },
      toDetail(record) {
        this.$router.push({
          path: '/bayareacertification/mediaCoverageDetail',
          query: {
            id: record.id,
            type: '1'
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  margin-top: 85px;
}

.banner {
  width: 1000px;
  height: 100%;
  margin: 0 auto;

  .title {
    margin: 33px 0;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  .content {
    display: flex;
    background-color: rgba(250, 250, 250, 1);
    margin-bottom: 30px;
    cursor: pointer;

    .left-img {
      width: 300px;
      height: 185px;
      text-align: center;
      padding: 10px 10px;
    }

    .right-text {
      width: 70%;
      display: flex;
      padding: 10px;

      .left {
        width: 90%;
        padding: 10px 30px 10px 0;
        transition: 0.3s all;

        .text-title {
          font-size: 20px;
          font-weight: bold;
        }

        .text-content {
          font-size: 18px;
        }

        &:hover {
          color: @primary-color;
        }
      }

      .right {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: @primary-color;

        .day {
          font-size: 24px;
          font-weight: bold;
        }

        .date {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .pagination {
    text-align: center;
    margin-bottom: 30px;
  }
}

.single-line-ellipsis {
  overflow: hidden; /* 隐藏溢出的内容 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 溢出的部分用省略号表示 */
}

.multi-line-ellipsis {
  display: -webkit-box; /* 使用弹性盒子模型 */
  -webkit-box-orient: vertical; /* 子元素垂直排列 */
  -webkit-line-clamp: 4; /* 显示的最大行数 */
  overflow: hidden; /* 隐藏溢出的内容 */
}
</style>
